var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout"},[_c('router-view'),_c('van-tabbar',{attrs:{"route":"","inactive-color":"#6F6F6F","active-color":"#ED301D","border":false},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"to":"/home"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"image",attrs:{"src":props.active
              ? require('@/assets/tabbar/home-opt.png')
              : require('@/assets/tabbar/home.png')}})]}}])},[_c('span',[_vm._v("首页")])]),_c('van-tabbar-item',{attrs:{"to":"/classify"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"image",attrs:{"src":props.active
              ? require('@/assets/tabbar/classification-opt.png')
              : require('@/assets/tabbar/classification.png')}})]}}])},[_c('span',[_vm._v("分类")])]),_c('van-tabbar-item',{attrs:{"to":"/shop-cart"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"image",attrs:{"src":props.active
              ? require('@/assets/tabbar/shopping-opt.png')
              : require('@/assets/tabbar/shopping-cart.png')}})]}}])},[_c('span',[_vm._v("购物车")])]),_c('van-tabbar-item',{attrs:{"to":"/my"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"image",attrs:{"src":props.active
              ? require('@/assets/tabbar/myicon-opt.png')
              : require('@/assets/tabbar/myicon.png')}})]}}])},[_c('span',[_vm._v("我的")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }