import Cookies from "js-cookie";
import { getShoppingNum } from "@/api/shopping";
import { getJsConfig } from "@/api/common";
import {Toast} from "vant";

let tools = {}

/**
 * 大小判断
 * @param a
 * @param b
 * @returns {number}
 */
tools.sortNumber = function (a, b) {
  return a - b;
}

/**
 * 获取购物车数量
 * @returns {Promise<number|acorn.TokenType|string>}
 */
tools.getShoppingNum = async function () {
  let num = Cookies.get('SHOPPING_NUM');
  if (num === undefined || num === 'undefined') {
    const ret = await getShoppingNum({ goods_id: this.goodsId });
    if (ret.code * 1 === 1) {
      num = ret.data.num;
      Cookies.set('SHOPPING_NUM', num);
    }
  }
  return num;
}


/**
 * 清除购物车数量
 */
tools.delShoppingNum = function () {
  Cookies.set('SHOPPING_NUM', undefined);
}

/**
 * 重新加载购物车数量
 * @returns {Promise<number|acorn.TokenType|string>}
 */
tools.restartShoppingNum = function () {
  Cookies.set('SHOPPING_NUM', undefined);
  return tools.getShoppingNum();
}
/**
 * 判断当前浏览器是否是微信
 * @returns {boolean}
 */
tools.isWx = function () {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') != -1) {
    return true;
  } else {
    return false;
  }
}

/**
 * 获取平台类型  1：微信，2：支付宝
 * @returns {boolean|number}
 */
tools.platformType = function () {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') != -1) {
    return 1;
  }else if(ua.indexOf('alipay') != -1){
    return 2;
  } else {
    return 0;
  }
}

/**
 * 微信JS初始化
 * @param jsApiList
 * @returns {Promise<boolean>}
 */
tools.wxInitialize = async function (jsApiList) {
  const ret = await getJsConfig({ 'url': window.location.href });
  if (ret.code * 1 === 1) {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: ret.data.appid, // 必填，公众号的唯一标识
      timestamp: ret.data.timestamp * 1, // 必填，生成签名的时间戳
      nonceStr: ret.data.noncestr, // 必填，生成签名的随机串
      signature: ret.data.signature,// 必填，签名
      jsApiList: jsApiList// 必填，需要使用的JS接口列表
    });
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });
    return true;
  } else {
    return false;
  }
}

tools.weiXinPay = function (data) {
  console.log({
    appId: data.appId, // 必填，公众号的唯一标识
    timestamp: data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
    nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
    package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
    signType: data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
    paySign: data.paySign, // 支付签名
  })
  return new Promise((resolve, reject) => {
    wxJs.chooseWXPay({
      appId: data.appId, // 必填，公众号的唯一标识
      timestamp: data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
      nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
      package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      signType: data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
      paySign: data.paySign, // 支付签名
      success: function (res) {
        resolve(true)
      },
      fail: function (res) {
        resolve(false)
      },
    });
  })
}

tools.error=function (msg){
  Toast.fail(msg);
}
/**
 * 价格处理
 * @returns {Number}
 */
tools.toInteger = function (num) {
  // 取整
  let toNum = "";
  toNum = parseInt(num);
  return toNum;
}
tools.twoFloating = function (num) {
  // 获取两位小数
  let price = "";
  price = num * 1;
  price = String(price).split(".")[1];
  if (price !== undefined && price.length === 1) {
    price = `.${price}0`;
  } else {
    price === undefined ? (price = ".00") : (price = `.${price}`);
  }
  return price;
}

tools.twoNum = function (num) {
  console.log(num);
  let price = String(num)
  if (price.split('.')[1] == '0000') {
    // price = price.split('.')[0] + '.00'
    price = parseInt(price)
  } else {
    price = num
  }
  return price
}
tools.fourFloat = function (num) {
  // 保留四位小数并除零
  let money = (Math.round(num * 10000) / 10000) * 1
  return money
}
export default tools
