<template>
  <div class="not-found-layout">
    <van-button block @click="onClick">点击返回主页</van-button>
    <img src="../../assets/404/404.png" class="not-found-img">

  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {

    }
  },
  methods: {
    onClick () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.not-found-layout{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}
.not-found-img {
  width: 100%;
}
</style>
