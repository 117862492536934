export default [
  {
    // 签到主页
    path: '/sign',
    name: 'sign',
    component: () => import('@/pages/sign/sign/index.vue')
  },
  {
    // 规则
    path: '/rule',
    name: 'rule',
    component: () => import('@/pages/sign/rule/index.vue')
  }
]
