export default [
  {
    path: '/address-list',
    name: 'address-list', // 地址选择页
    component: () => import('@/pages/my/address-list/address-list')
  },
  {
    path: '/address-update',
    name: 'address-update', // 地址选择
    component: () => import('@/pages/my/address-update/address-update')
  },
  {
    path: '/alter-user-info',
    name: 'alter-user-info', // 修改用户信息
    component: () => import('@/pages/my/alter-user-info/alter-user-info.vue')
  },
  {
    path: '/bind-mobile',
    name: 'bind-mobile', // 修改绑定手机号
    component: () => import('@/pages/my/alter-user-info/bind-mobile')
  },
  {
    path: '/alter-password',
    name: 'alter-password', // 修改密码
    component: () => import('@/pages/my/alter-user-info/alter-password.vue')
  },
  {
    path: '/shop-collection',
    name: 'shop-collection', // 收藏店铺
    component: () => import('@/pages/my/shop-collection/shop-collection.vue')
  },

  {
    path: '/my-collection',
    name: 'my-collection', // 我的收藏
    component: () => import('@/pages/my/my-collection/my-collection.vue')
  },
  {
    path: '/remaining-sum',
    name: 'remaining-sum', // 余额
    component: () => import('@/pages/my/remaining-sum/remaining-sum.vue')
  },
  {
    path: '/remain-cash',
    name: 'remain-cash', // 提现
    component: () => import('@/pages/my/remaining-sum/remain-cash.vue')
  },
  {
    path: '/divide-sell',
    name: 'divide-sell', // 分销
    component: () => import('@/pages/my/divide-sell/divide-sell.vue')
  },
  {
    path: '/county-supervise',
    name: 'county-supervise',
    component: () => import('@/pages/my/divide-sell/county-supervise.vue')
  },
  {
    path: '/group-supervise',
    name: 'group-supervise', // 团队管理
    component: () => import('@/pages/my/divide-sell/group-supervise.vue')
  },
  {
    path: '/sell-supervise',
    name: 'sell-supervise', // 团队管理
    component: () => import('@/pages/my/divide-sell/sell-supervise.vue')
  },
  {
    // 更多功能-扫码支付
    path: '/scan-code',
    name: 'scan-code',
    component: () => import('@/pages/my/more-operation/scan-code/scan-code.vue')
  },
  {
    // 个人中心-我的评价
    path: '/my-evaluate',
    name: 'my-evaluate',
    component: () => import('@/pages/my/more-operation/await-evaluate/my-evaluate.vue')
  },
  {
    // 个人中心-商品评价
    path: '/publish-evaluate',
    name: 'publish-evaluate',
    component: () => import('@/pages/my/more-operation/await-evaluate/publish-evaluate.vue')
  },
  {
    // 个人中心-评价成功
    path: '/publish-succeed',
    name: 'publish-succeed',
    component: () => import('@/pages/my/more-operation/await-evaluate/publish-succeed.vue')
  },

  {
    // 更多功能-公益豆
    path: '/my-beans',
    name: 'my-beans',
    component: () => import('@/pages/my/more-operation/benefit-beans/my-beans.vue')
  },
  {
    // 更多功能-公益豆消费情况
    path: '/beans-consume',
    name: 'beans-consume',
    component: () => import('@/pages/my/more-operation/benefit-beans/beans-consume.vue')
  },

  {
    // 更多功能-消息界面
    path: '/my-news',
    name: 'my-news',
    component: () => import('@/pages/my/more-operation/my-news/my-news.vue')
  },
  {
    // 更多功能-聊天界面
    path: '/consult',
    name: 'consult',
    component: () => import('@/pages/my/more-operation/my-news/consult.vue')
  },
  {
    // 更多功能-通知详情
    path: '/inform-details',
    name: 'inform-details',
    component: () => import('@/pages/my/more-operation/system-inform/inform-details.vue')
  },
  {
    // 更多功能-系统通知通知
    path: '/system-inform',
    name: 'system-inform',
    component: () => import('@/pages/my/more-operation/system-inform/system-inform.vue')
  },
  {
    // 更多功能-系统通知通知
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/pages/my/my/moudles/about-us/about-us')
  },
  {
    // 分销收益
    path: '/divide-earnings',
    name: 'divide-earnings',
    component: () => import('@/pages/my/divide-sell/divide-earnings.vue')
  },
  {
    // 分销收益
    path: '/shop-earnings',
    name: 'shop-earnings',
    component: () => import('@/pages/my/divide-sell/shop-earnings.vue')
  },
]
