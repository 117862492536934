import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import './style/common.css'
import 'lib-flexible'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import '@/icons'
const EventBus = new Vue();
import VueClipboard from 'vue-clipboard2'
Vue.prototype.$video = Video
/**
 * vant组件注册
 */
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import VueAudio from 'vue-audio-better'
Vue.use(VueAudio)
/**
 * 自定义组件
 */
import Price from "@/components/Price/index.vue";
Vue.component('Price', Price)

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus
    }
  }
})

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

// 注册cookies
Vue.use(VueCookies)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
