<template>
  <div class="loading-page" v-if="show" @touchmove.prevent>
    <div class="loading-box">
      <van-loading type="spinner" size="46" vertical color="#fff">
        <span class="msg-text">{{ message }}</span>
      </van-loading>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "加载中",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  // background-color: #fff;
  z-index: 9000;
  .loading-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 120px;
    height: 120px;
    background-color: rgba(0, 0, 0, 0.5);
    .msg-text {
      font-size: 15px;
    }
  }
}
</style>