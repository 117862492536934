import axios from 'axios'
import store from '@/store'
import route from '@/router/'
import tools from "@/common/js/tools";

// 封装ajax
const service = axios.create({
  // baseURL: '/api/', // 设置axios请求的基础的基础地
})
service.interceptors.request.use(function (config) {
  let { data } = config
  // console.log(data)
  if (data !==undefined  && data.openLoding === 1) {
    store.state.loadingShow = true
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})
/**
 * 响应拦截器
 */
service.interceptors.response.use(
  // 对响应的回来的数据进行操作
  res => {
    const { data } = res
    store.state.loadingShow = false
    if (data.code !== undefined && data.code === 401) {
      store.commit('setRemove')
      if (tools.isWx()) {
        let inviteCode = localStorage.getItem('INVITE_CODE')
        let url = window.location.href;
        let Base64 = require('js-base64').Base64;
        let goUrl = 'app.jhcs888.com';
        if(window.location.host === 'demo.jhcs888.com'){
          goUrl='demo' + goUrl;
        }else if (window.location.host !== 'wap.jhcs888.com') {
          goUrl = 'test' + goUrl;
        }
        window.location.href = "http://" + goUrl + "/wx_info?redirect_url=" + Base64.encode(url) + "&inviteCode=" + inviteCode
      } else {
        route.push('/login-page')
      }
    }
    return data
  }, error => {
    return Promise.reject(error)
  }
)

const request = (url, method = 'get', data) => {
  const config = {}
  data.m_id = store.state.m_id;
  data.token = store.state.token;
  if (method === 'get') {
    config.params = data
  } else {
    config.data = data
  }
  return service({
    url,
    method,
    ...config
  })
}
export default request
