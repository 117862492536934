export default [
  {
    // 商品详情页入口
    path: 'detail',
    name: 'detail',
    component: () => import('@/pages/new-goods/detail')
  },
  //普通商品详情页
  {
    path: '/goods',
    name: 'goods',
    // component: () => import('@/pages/goods/goods/goods.vue')
    component: () => import('@/pages/new-goods/detail')
  },
  {
    // 秒杀商品详情
    path: '/seckill-details',
    name: 'seckill-details',
    // component: () => import('@/pages/goods/seckill/seckill-details.vue')
    component: () => import('@/pages/new-goods/detail')
  },
  {
    // 进货详情
    path: '/stock-details',
    name: 'stock-details',
    // component: () => import('@/pages/home/stock/stock-details.vue')
    component: () => import('@/pages/new-goods/detail')
  },
  {
    // 公益豆商品详情
    path: '/beans-details',
    name: 'beans-details',
    // component: () => import('@/pages/home/beans-shopping/beans-details.vue')
    component: () => import('@/pages/new-goods/detail')
  },
  {
    // 评论页
    path: 'evaluate-page',
    name: 'evaluate-page',
    component: () => import('@/pages/new-goods/components/evaluate-page/evaluate-page.vue')
  },

]