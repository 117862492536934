import request from './ajax'

const commonUrl = '/api/common/'
// 发送短信
export const commonSend = (data) => request(commonUrl + 'send', 'post', { ...data })
// base64位图片上传
export const commonBase64 = (data) => request(commonUrl + 'base64', 'post', { ...data })
// 文件图片上传
export const commonFile = (data) => request(commonUrl + 'file', 'post', { ...data })
// 获取js签名信息
export const getJsConfig = (data) => request(commonUrl + 'js', 'post', { ...data })
//获取系统消息
export const getSysMessage = (data) => request(commonUrl + 'message', 'post', { ...data })
//IP定位
export const getLocation = (data) => request(commonUrl + 'location', 'post', { ...data })
//根据经纬度获取定位信息
export const getLocationAddress = (data) => request(commonUrl + 'address', 'post', { ...data })
//获取阿里云权限信息
export const getAliSts = (data) => request(commonUrl + 'sts', 'post', { ...data })
//倒计时
export const getStartTime = (data) => request(commonUrl + 'start', 'post', { ...data })
//倒计时
export const getAppVersion = (data) => request(commonUrl + 'app_version', 'post', { ...data })
//隐私协议
export const getPrivacyInfo = (data) => request(commonUrl + 'privacy_info', 'post', { ...data })
