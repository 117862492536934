export default [
  {
    // 首页-本地生活-商品列表
    path: '/native-live',
    name: 'native-live',
    component: () => import('@/pages/home/class-nav/native-live.vue')
  },
  {
    // 首页-店铺主页
    path: '/shop-home',
    name: 'shop-home',
    component: () => import('@/pages/home/shop-home/shop-home.vue')
  },
  {
    // 更多功能-我要开店
    path: '/register-info',
    name: 'register-info',
    component: () => import('@/pages/my/more-operation/my-shop/register-info.vue')
  },
  {
    // 入住协议
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/pages/my/more-operation/my-shop/agreement')
  },
  {
    // 隐私声明
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/pages/my/more-operation/my-shop/privacy')
  },
]
