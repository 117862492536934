import Vue from 'vue'
import VueRouter from 'vue-router'
import tabbar from '../layout/tab-bar/tab-bar.vue'
import notFound from '../pages/404/not-found.vue'
import Cookies from 'js-cookie'
import store from '@/store'
// 商品模块路由
import goodsRouter from './moudles/goods.js'
// 会员模块路由
import memberRouter from './moudles/member.js'
// 订单模块路由
import orderRouter from './moudles/order'
// 门店模块路由
import shopRouter from './moudles/shop'
// 秒杀模块路由
import seckillRouter from './moudles/seckill'
// 进货专区路由
import purchasesRouter from './moudles/purchases'
// 公益豆路由
import benefitRouter from './moudles/benefit'
import count from './moudles/count'
/**
* 重绘
*/
// 商品详情页
import newDetail from './moudles/new-detail'
// 二手市场模块
import used from './moudles/used'
// 云服务
import cloud from './moudles/cloud'
// 聊天
import chitchat from './moudles/chitchat'
import discounts from './moudles/discounts'
import signIn from './moudles/sign-in'
import tools from "@/common/js/tools";
// 抢购
import rob from "./moudles/rob";
// 跑腿
import run from "./moudles/run";
import pay from "./moudles/pay";


import {userWxAppletLogin} from '@/api/member.js'
import {Base64} from "js-base64";
import {Toast} from "vant";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/login.vue')
  },
  {
    path: '/download-app',
    name: 'download-app',
    component: () => import('@/pages/download-app')
  },
  {
    path: '/404',
    name: '404',
    component: notFound
  },
  {
    path: '/login-page',
    name: 'login-page',
    component: () => import('@/pages/login/login-page.vue')
  },
  {
    path: '/search-goods',
    name: 'search-goods',
    component: () => import('@/pages/goods/goods-list/moudles/search-goods.vue')
  },

  {
    path: '/',
    name: 'tabbar',
    component: tabbar,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/pages/home/home/home.vue')
      },
      {
        path: 'classify',
        name: 'classify',
        component: () => import('@/pages/classify/classify.vue')
      },
      {
        path: 'shop-cart',
        name: 'shop-cart',
        component: () => import('@/pages/shop-cart/shop-cart.vue')
      },
      {
        path: 'my',
        name: 'my',
        component: () => import('@/pages/my/my/my.vue')
      }
    ]
  },
  {
    // 浏览记录
    path: '/browse-record',
    name: 'browse-record',
    component: () => import('@/pages/my/browse-record/index.vue')
  },
  {
    // 商品列表
    path: '/comments-page',
    name: 'comments-page',
    component: () => import('@/pages/goods/goods/moudles/comments-page.vue')
  },
  {
    // 商品列表
    path: '/commodity',
    name: 'commodity',
    component: () => import('@/pages/goods/goods-list/goods-list.vue')
  },
  {
    // 订单提交页
    path: '/submit-order',
    name: 'submit-order',
    component: () => import('@/views/submit-order/submit-order.vue')
  },


  {
    // 物流信息
    path: '/examine-logistics',
    name: 'examine-logistics',
    component: () => import('../pages/order/common-order/examine-logistics.vue')
  },
  {
    // 售后处理
    path: '/after-sale',
    name: 'after-sale',
    component: () => import('../pages/order/purchases-service/after-sale.vue')
  },

  {
    // 退款 成功
    path: '/refund-succeed',
    name: 'refund-succeed',
    component: () => import('../pages/order/refund-procedure/refund-succeed.vue')
  },
  {
    // 进货金
    path: '/stock-gold',
    name: 'stock-gold',
    component: () => import('../pages/my/more-operation/stock-gold/stock-gold.vue')
  },
  // {
  //   // 进货金
  //   path: '/stock-li',
  //   name: 'stock-li',
  //   component: () => import('../pages/my/more-operation/stock-gold/stock-li.vue')
  // },
  {
    // 支付成功---2
    path: '/pay-ok',
    name: 'pay-ok',
    component: () => import('../pages/order/pay-success/pay-ok.vue')
  },
  {
    // 支付成功---3
    path: '/test',
    name: 'test',
    component: () => import('@/pages/login/test')
  },
  {
    // 购买功能操作
    path: '',
    name: '',
    component: () => import('../layout/goods-action/goods-action.vue'),
    children: newDetail
  },
  ...goodsRouter,
  ...memberRouter,
  ...orderRouter,
  ...shopRouter,
  ...seckillRouter,
  ...purchasesRouter,
  ...benefitRouter,
  ...used,
  ...cloud,
  ...chitchat,
  ...count,
  ...discounts,
  ...signIn,
  ...rob,
  ...run,
  ...pay
]
const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  mode: 'history'
})

const whiteList = ['/404', '/home', '/classify', '/login-page', '/download-app'] // 自定义白名单

router.beforeEach( async (to, from, next) => {

  let status = Cookies.get('STATUS')
  let token = to.query.token;
  let m_id = to.query.m_id;

  if (token && m_id) {
    store.commit("setCookie", { 'token': token, 'm_id': m_id });
  }
  if (to.query.inviteCode) {
    localStorage.setItem('INVITE_CODE', to.query.inviteCode)
  }

  if (store.state.token && store.state.m_id) {
    if (to.path === '/login-page') {
      next('/')
    } else {
      next()
    }
  } else {

    let isWx = tools.isWx();
    if (isWx && to.path !== '/login-page') {
      // let inviteCode = localStorage.getItem('INVITE_CODE')

      // let url = window.location.href;
      // let Base64 = require('js-base64').Base64;
      // let goUrl = 'app.jhcs888.com';
      // if (window.location.host === 'demo.jhcs888.com') {
      //   goUrl = 'demoapp.jhcs888.com';
      // } else if (window.location.host !== 'wap.jhcs888.com') {
      //   goUrl = 'test' + goUrl;
      // }

      // window.location.href = "https://" + goUrl + "/wx_info?redirect_url=" + Base64.encode(url) + "&inviteCode=" + inviteCode


      const href = window.location.href;

      if (href.indexOf('?code=') > -1 || href.indexOf('&code=') > -1){
      	const urlArr = href.split('?')
      	const rightUrlArr = urlArr[1].split('#/')
      	const queryObj = {}
      	rightUrlArr[0].split('&').map((item) => {
      		const splitStr = item.split('=')
      		return {
      			key: splitStr[0],
      			value: splitStr[1],
      		}
      	}).forEach((item) => {
      		queryObj[item.key] = item.value
      	})
      	if(urlArr.length>=3){
      		rightUrlArr[1]= "?"+urlArr[2]
      	}

      	let from = {
      		code: queryObj.code,
      		wx_type:1,
      	}
      	await userWxAppletLogin(from).then((res)=>{
      		if (res.code === 1) {
            store.commit("setCookie", { 'token': res.data.token, 'm_id': res.data.m_id });
            next(to.path)
      		} else {
            Toast.fail(res.msg);
            next('/home')
      			// tools.error(res.msg);
      		}
      	})
      }else{
      	store.commit("setCookie", { 'token': '', 'm_id': '' });
        let url=window.location.href;
        url = url.replace('&code=', '&code_a=1')
        url = encodeURIComponent(url);
        window.location.href = `https://wx.jhcs888.com/#/?redirect_url=${Base64.encode(url)}&type=3`
      }
    } else {
      if (whiteList.indexOf(to.path) > -1) {
        next()
      } else {
        next('/login-page') // 如果去其他页面，会被路由守卫阻止，强制去登录页面
      }
    }
  }
})

// 解决router版本兼容问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
