import request from './ajax'
// 会员路由模块
const memberUrl = '/api/member/'
// 账户合并
export const setUpdateInfo = (data) => request(memberUrl + 'update_info', 'post', { ...data })
// 修改密码
export const setPassword = (data) => request(memberUrl + 'password', 'post', { ...data })
// 获取收货地址列表
export const getAddressList = (data) => request(memberUrl + 'addresses', 'post', { ...data })
// 获取收货地址详情
export const getAddressInfo = (data) => request(memberUrl + 'address', 'post', { ...data })
// 编辑收货地址
export const editAddress = (data) => request(memberUrl + 'edit_address', 'post', { ...data })
// 删除收货地址
export const delAddress = (data) => request(memberUrl + 'del_address', 'post', { ...data })
// 设置默认收货地址
export const defaultAddress = (data) => request(memberUrl + 'default_address', 'post', { ...data })
// 设置支付密码
export const setPayPass = (data) => request(memberUrl + 'pay_pass', 'post', { ...data })
// 获取会员资金信息
export const getMoneyInfo = (data) => request(memberUrl + 'money', 'post', { ...data })
// 获取会员详情
export const getMemberInfo = (data) => request(memberUrl + 'info', 'post', { ...data })
// 获取会员分享信息
export const getShareInfo = (data) => request(memberUrl + 'share', 'post', { ...data })
// 更新会员基本信息
export const updateMemberInfo = (data) => request(memberUrl + 'update', 'post', { ...data })
// 修改手机号码
export const updatePhone = (data) => request(memberUrl + 'update_phone', 'post', { ...data })
// 获取团队基本信息
export const getTeamInfo = (data) => request(memberUrl + 'team_info', 'post', { ...data })
//获取团队列表
export const getTeamList = (data) => request(memberUrl + 'team_list', 'post', { ...data })
export const getMySalesTotal = (data) => request(memberUrl + 'sales', 'post', { ...data })
export const getMySalesList = (data) => request(memberUrl + 'sales_list', 'post', { ...data })
// 足迹
export const getGoodsBrowse = (data) => request(memberUrl + 'goods_browse', 'post', { ...data, openLoding: 1 })
// 删除足迹
export const delBrowse = (data) => request(memberUrl + 'del_browse', 'post', { ...data, openLoding: 1 })

// 微信登录注册
export const userWxAppletLogin = (data) => request(memberUrl + 'new_applet_login', 'post', { ...data })
