export default [
  {
    path: '/goods-list',
    name: 'goods-list',
    component: () => import('@/pages/goods/goods-list/goods-list.vue')
  },
  // {
  //   path: '/goods',
  //   name: 'goods',
  //   // component: () => import('@/pages/goods/goods/goods.vue')
  //   component: () => import('@/pages/new-goods/detail')
  // },
  {
    path: '/create-order',
    name: 'create-order',
    component: () => import('@/pages/goods/create-order/create-order.vue')
  },
  {
    // 消费金-专区
    path: '/gold-list',
    name: 'gold-list',
    component: () => import('@/pages/home/consume-gold/gold-list.vue')
  },
  {
    // 会员折扣-专区
    path: '/discount-list',
    name: 'discount-list',
    component: () => import('@/pages/home/discount-gold/discount-list.vue')
  },
  {
    // 京东商品
    path: '/Jingdong',
    name: 'Jingdong',
    component: () => import('@/pages/goods/Jingdong/index.vue')
  },
  {
    // 天猫商品
    path: '/Tmall',
    name: 'Tmall',
    component: () => import('@/pages/goods/Tmall/index.vue')
  },
  {
    // 新人专享
    path: '/new-user',
    name: 'new-user',
    component: () => import('@/pages/goods/new-user/index.vue')
  },
]
