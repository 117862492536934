export default [
  {
    // 秒杀模块
    path: '/seckill-time',
    name: 'seckill-time',
    component: () => import('@/pages/goods/seckill/seckill-time.vue')
  },
  // {
  //   // 秒杀商品详情
  //   path: '/seckill-details',
  //   name: 'seckill-details',
  //   // component: () => import('@/pages/goods/seckill/seckill-details.vue')
  //   component: () => import('@/pages/new-goods/detail')
  // },
]
