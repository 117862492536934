export default [
  {
    path: '/seize-goods',
    name: 'seize-goods',
    component: () => import('@/pages/seize-goods/goods-list')
  },
  {
    path: '/seize-goods-det',
    name: 'seize-goods-det',
    component: () => import('@/pages/seize-goods/goods-det')
  },
  {
    path: '/seize-shop-cart',
    name: 'seize-shop-cart',
    component: () => import('@/pages/seize-goods/shop-cart')
  },
]