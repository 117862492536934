export default [
  {
    path: '/run-index',
    name: 'run-index',
    component: () => import('@/pages/run-errands/run-index')
  },
  {
    path: '/run-det',
    name: 'run-det',
    component: () => import('@/pages/run-errands/run-det')
  },
  {
    path: '/order-det',
    name: 'order-det',
    component: () => import('@/pages/run-errands/order-det')
  },
]
