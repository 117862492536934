export default [
  {
    // 首页-
    path: '/beans-shopping',
    name: 'beans-shopping',
    component: () => import('@/pages/home/beans-shopping/beans-shopping.vue')
  },
  // {
  //   // 公益豆商品详情
  //   path: '/beans-details',
  //   name: 'beans-details',
  //   // component: () => import('@/pages/home/beans-shopping/beans-details.vue')
  //   component: () => import('@/pages/new-goods/detail')
  // },
  {
    // 公益豆商品提交订单
    path: '/commit-beans',
    name: 'commit-beans',
    component: () => import('@/pages/home/beans-shopping/commit-order.vue')
  },

]
