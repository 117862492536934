import request from './ajax'
// 购物车模块路由
const shoppingUrl = '/api/shopping/'
// 添加购物车
export const addShopping = (data) => request(shoppingUrl + 'add', 'post', { ...data })
// 获取购物车列表
export const getShoppingGoods = (data) => request(shoppingUrl + 'goods', 'post', { ...data })
// 修改购物车数量
export const saveShoppingNum = (data) => request(shoppingUrl + 'save', 'post', { ...data })
// 设置购物车商品选中
export const setSelect = (data) => request(shoppingUrl + 'select', 'post', { ...data })
// 删除购物车商品
export const delShopping = (data) => request(shoppingUrl + 'del', 'post', { ...data })
// 获取购物车商品总价信息
export const getTotalPrice = (data) => request(shoppingUrl + 'total', 'post', { ...data })
// 获取购物车数量
export const getShoppingNum = (data) => request(shoppingUrl + 'num', 'post', { ...data })
export const verifyGoodsArea = (data) => request(shoppingUrl + 'verify', 'post', { ...data })
