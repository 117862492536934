export default [
  {
    path: '/pay-success',
    name: 'pay-success', // 地址选择页
    component: () => import('@/pages/order/pay-success/pay-success')
  },
  {
    // 订单列表
    path: '/all-order',
    name: 'all-order',
    component: () => import('@/pages/order/common-order/all-order')
  },
  {
    // 订单详情
    path: '/my-order-details',
    name: 'my-order-details',
    component: () => import('@/pages/order/common-order/my-order-details.vue')
  },
  {
    // 退货退款订单
    path: '/sell-behind',
    name: 'sell-behind',
    component: () => import('@/pages/order/refund-procedure/sell-behind.vue')
  },
  {
    // 退货中
    path: '/sales-return',
    name: 'sales-return',
    component: () => import('@/pages/order/refund-procedure/sales-return.vue')
  },
  {
    // 退款申请
    path: '/refund-serve',
    name: 'refund-serve',
    component: () => import('@/pages/order/refund-procedure/refund-serve.vue')
  },
  {
    // 退款 成功
    path: '/refund-succeed',
    name: 'refund-succeed',
    component: () => import('@/pages/order/refund-procedure/refund-succeed.vue')
  },
  {
    // 进货订单列表
    path: '/purchases-order',
    name: 'purchases-order',
    component: () => import('@/pages/order/common-order/purchases-order')
  },

]
