export default [
  {
    path: '/chitchat',
    name: 'chitchat',
    component: () => import('@/pages/chitchat')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('@/pages/chitchat/information/information')
  },
]
