import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import Cookies from 'js-cookie'
import { getShoppingNum, addShopping } from '../api/shopping'
const state = {
  status: Cookies.get('STATUS') || '',
  token: Cookies.get('TOKEN') || '',
  m_id: Cookies.get('M_ID') || '',
  shoppingCartNum: '',
  closeTime: false,
  loadingShow: false,
  startTime: Cookies.get('start_time') || '',
}
const mutations = {
  setStstues(state, code) {
    Cookies.set('STATUS', 1)
    state.status = code
  },
  setCookie(state, tokenData) {
    Cookies.set('TOKEN', tokenData.token)
    Cookies.set('M_ID', tokenData.m_id)
    state.token = tokenData.token
    state.m_id = tokenData.m_id
  },
  setRemove(state) {
    Cookies.remove('TOKEN')
    Cookies.remove('M_ID')
    Cookies.remove('start_time')
    state.token = ''
    state.m_id = ''
    state.startTime = 0
  },
  setCartNum(state, num) {
    state.shoppingCartNum = num
  },
  setTime(state, bool) {
    state.closeTime = bool
  },
  changeIsLoading(state, data) {
    state.loadingShow = data
  },
  countTime(state, data) {
    Cookies.set('start_time', data)
    state.startTime = data
  }
}
const actions = {
  async getShoppingNum(context, data) {
    let shoppingNum = ''
    const res = await getShoppingNum()
    shoppingNum = res.data.num
    context.commit('setCartNum', res.data.num)
    return shoppingNum
  },
}
export default new Vuex.Store({
  state,
  mutations,
  actions
})
