export default [
  {
    // 进货-专区
    path: '/stock-list',
    name: 'stock-list',
    component: () => import('@/pages/home/stock/stock-list.vue')
  },
  // {
  //   // 进货详情
  //   path: '/stock-details',
  //   name: 'stock-details',
  //   // component: () => import('@/pages/home/stock/stock-details.vue')
  //   component: () => import('@/pages/new-goods/detail')
  // },
  {
    // 进货订单-确认
    path: '/confirm-order',
    name: 'confirm-order',
    component: () => import('@/pages/home/stock/confirm-order.vue')
  },
]
