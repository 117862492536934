export default [
  // 详情tab页
  {
    path: '/',
    name: '',
    component: () => import('@/pages/used/used'),
    children: [
      {
        path: 'used',
        name: 'used',
        component: () => import('@/pages/used/used/used-list/used-list.vue')
      },
      {
        path: 'my-used',
        name: 'my-used',
        component: () => import('@/pages/used/used/my-used/my-used.vue')
      },
      {
        path: 'ascertain-order',
        name: 'ascertain-order',
        component: () => import('@/pages/used/used/ascertain-order/ascertain-order.vue')
      },
    ]
  },
  // 闲置商品详情
  {
    path: '/used-details',
    name: 'used-details',
    component: () => import('@/pages/used/used-details')
  },
  // 闲置订单详情
  {
    path: '/used-order-details',
    name: 'used-order-details',
    component: () => import('@/pages/used/order-details')
  },
  // 闲置商品创建订单
  {
    path: '/creation-order',
    name: 'creation-order',
    component: () => import('@/pages/used/creation-order')
  },
  // 闲置卖家信息
  {
    path: '/seller-info',
    name: 'seller-info',
    component: () => import('@/pages/used/seller-info')
  },
  // 闲置信息发布
  {
    path: '/used-publish',
    name: 'used-publish',
    component: () => import('@/pages/used/used-publish')
  },
  {
    // 支付成功
    path: '/used-pay-success',
    name: 'used-pay-success',
    component: () => import('@/pages/used/pay-success/pay-success')
  },
  {
    // 查看物流
    path: '/look-logistics',
    name: 'look-logistics',
    component: () => import('@/pages/used/look-logistics/look-logistics')
  },
]
