export default [
  {
    path: '/discounts',
    name: 'discounts',
    component: () => import('@/pages/discounts/goods-page/index.vue')
  },
  {
    path: '/my-discounts',
    name: 'my-discounts',
    component: () => import('@/pages/discounts/my-discounts/index.vue')
  },
  {
    path: '/get-centre',
    name: 'get-centre',
    component: () => import('@/pages/discounts/get-centre/index.vue')
  },
  {
    path: '/discounts-info',
    name: 'discounts-info',
    component: () => import('@/pages/discounts/discounts-info/index.vue')
  },
]