<template>
  <div class="layout">
    <router-view></router-view>
    <van-tabbar
      v-model="active"
      route
      inactive-color="#6F6F6F"
      active-color="#ED301D"
      :border="false"
    >
      <van-tabbar-item to="/home">
        <span>首页</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('@/assets/tabbar/home-opt.png')
                : require('@/assets/tabbar/home.png')
            "
            class="image"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/classify">
        <span>分类</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('@/assets/tabbar/classification-opt.png')
                : require('@/assets/tabbar/classification.png')
            "
            class="image"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/shop-cart">
        <span>购物车</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('@/assets/tabbar/shopping-opt.png')
                : require('@/assets/tabbar/shopping-cart.png')
            "
            class="image"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/my">
        <span>我的</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('@/assets/tabbar/myicon-opt.png')
                : require('@/assets/tabbar/myicon.png')
            "
            class="image"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style lang="less" scoped>
.image {
  width: 31px;
  height: auto;
}
.van-tabbar {
  border-top: 1px solid rgb(243, 243, 243);
  height: 63px;
}
/deep/.van-tabbar-item__icon img {
  height: auto;
}
/deep/ .van-tabbar-item__text {
  font-size: 14px;
}
</style>
