// 通过 Socket.io 客户端发起 WebSocket 请求
import io from 'socket.io-client';
import store from '@/store'
import route from '@/router/'
import { Toast } from "vant";


//'wss://testapp.jhcs888.com'
//'wss://apinew.lcpcp.cc'
let socketUrl = 'demoapi.jhcs888.com';
if (window.location.host !== 'wap.jhcs888.com') {
  // socketUrl = 'test' + socketUrl;
}


const socket = io('wss://' + socketUrl, {
  path: '/wss',
  transports: ['websocket']
});
// const socket = io('ws://127.0.0.1:9099', {
//   query: 'username=username'  ,
//   path: '/wss',
//   transports: ['websocket']
// });

//登陆事件
socket.wapLogin = function () {
  console.log('当前聊天m-id', store.state.m_id);
  let data = {};
  data.sid = socket.io.engine.id;
  data.m_id = store.state.m_id;
  data.token = store.state.token;
  socket.emit('wapLogin', data);
}

//注册连接信息
socket.on('connect', async () => {
  console.log('websocket connected: ' + socket.connected);
  //长连接登陆
  socket.wapLogin();
});

//注册关闭
socket.on('disconnect', () => {
  console.log('websocket connected: ' + socket.disconnect);
  console.log('连接断开了')
});

//登陆通知
socket.on('wapLogin', (ret) => {
  if (ret.status !== undefined && ret.status === 401) {
    store.commit('setRemove')
    route.push('/login-page')
  } else if (ret.status !== 1) {
    // Toast.fail(ret.msg);
  }
})

//登陆通知验证
socket.on('noLogin', () => {
  socket.wapLogin();
});






export default socket;
