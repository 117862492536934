<template>
  <div class="goods-price">
    <span
      class="common"
      :style="[{ fontSize: `${decimalStyle}px` }, { color: spanColor }]"
      >￥</span
    >
    <span
      class="integer"
      :style="[{ fontSize: `${integerStyle}px` }, { color: spanColor }]"
      >{{ toInteger(this.price) || "0" }}</span
    >
    <span
      class="common"
      :style="[{ fontSize: `${decimalStyle}px` }, { color: spanColor }]"
      >{{ twoFloating(this.price) || ".00" }}</span
    >
  </div>
</template>

<script>
import { toInteger, twoFloating } from "@/common/js/utils";
export default {
  name: "Price",
  props: {
    price: {
      default: "0.00",
    },
    decimalStyle: {
      default: 12,
    },
    integerStyle: {
      default: 18,
    },
    spanColor: {
      default: "#ed301d",
    },
  },
  data() {
    return {};
  },
  watch: {
    decimalStyle(n, o) {
      console.log(n);
    },
    integerStyle(n, o) {
      console.log(n);
    },
  },
  mounted() {},
  methods: {
    toInteger(num) {
      return toInteger(num);
    },
    twoFloating(num) {
      return twoFloating(num);
    },
  },
};
</script>

<style lang="less" scoped>
.goods-price {
  .integer {
    color: #ed301d;
    font-size: 18px;
  }
  .common {
    color: #ed301d;
    font-size: 12px;
  }
}
</style>