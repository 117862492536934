<template>
  <div>
    <router-view />
    <Loading :show="loadingShow"></Loading>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Loading from "@/components/loading";

import socket from "@/common/js/socket";
import store from '@/store'
import { Toast } from "vant";

export default {
  data(){
    return{
      audio:null,
      audioList:[],
      isPlay:false
    }
  },
  components: {
    Loading,
  },
  computed: {
    ...mapState(["loadingShow"]),
  },
  mounted() {
    // console.log('当前聊天m-id', store.state.m_id);
    // // this.getMySocketInfo()
    // // clearInterval(myTimeDisplay);
    // let myTimeDisplay = setInterval(() => {
      // this.getMySocketInfo();
      // this.audioList.push('http://demoapi.jhcs888.com/code/mp3/20230204/c891ea234cb3983e9fd358c76ad8a118.mp3')
      // this.playAudio()
    // }, 1000);
    // socket.on("userInformOrder", (ret) => {
    //   if (ret.code * 1 === 1) {
    //     Toast.fail(ret.msg);
    //     if(ret.data.url){
    //       Toast.fail(ret.data.url);
    //       this.audioList.push(ret.data.url)
    //       this.playAudio()
    //     }
    //   } else {
    //     // Toast.fail(ret.msg);
    //   }
    // });
  },
  methods:{
    getMySocketInfo(){
      socket.emit('userInformOrder', {m_id:store.state.m_id});
    },
    playAudio(){
      Toast.fail('开始播报');
      if(this.isPlay){
        return
      }
      this.isPlay=true
      this.audio = new Audio()
      this.audio.src = this.audioList[0]
      this.audio.play()
      this.audio.addEventListener('ended',this.limitPlay)
    },
    limitPlay(){
      Toast.fail('播报完成');
      this.isPlay=false
      //消息队列中第一条消息播放完成后,判断消息队列总有几条
      if(this.audioList.length===1){
        this.audio.pause()
        this.audioList.shift()
      }else{
        //有多数据时,第一条播放完删掉第一条消息数据后,直接显示播放下一条消息
        this.audioList.shift()
        this.playAudio()
      }
    },

  },
};
</script>
<style lang="less">
</style>
