export const toInteger = function (num) {
    // 取整
    let toNum = "";
    toNum = parseInt(num);
    return toNum;
}
export const twoFloating = function (num) {
    // 获取两位小数
    let price = "";
    price = num * 1;
    price = String(price).split(".")[1];
    if (price !== undefined && price.length === 1) {
        price = `.${price}0`;
    } else {
        price === undefined ? (price = ".00") : (price = `.${price}`);
    }
    return price;
}

export const keepTwoDecimalFull = function (num, p) {
    let result = parseFloat(num);
    if (isNaN(result)) {
        return false;
    }
    let multiplicator = Math.pow(10, p);
    result = Math.round(num * multiplicator) / multiplicator;
    let s_x = result.toString();
    let pos_decimal = s_x.indexOf(".");
    if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
    }
    while (s_x.length <= pos_decimal + p) {
        s_x += "0";
    }
    return s_x;
}
/**
 * 获取开发平台
 * @returns {string}
 */
export const getPlatform = function () {
    let platForm = undefined;
    // #ifdef H5
    platForm = 'H5';
    //#endif
    // #ifdef APP-PLUS
    platForm = 'APP';
    //#endif
    // #ifdef APP-PLUS-NVUE
    platForm = 'APP';
    //#endif
    // #ifdef APP-NVUE
    platForm = 'APP';
    //#endif
    // #ifdef MP-WEIXIN
    platForm = 'MP-WEIXIN';
    //#endif
    // #ifdef MP-ALIPAY
    platForm = 'MP-ALIPAY';
    //#endif
    // #ifdef MP-BAIDU
    platForm = 'MP-BAIDU';
    //#endif
    // #ifdef MP-TOUTIAO
    platForm = 'MP-TOUTIAO';
    //#endif
    // #ifdef MP-LARK
    platForm = 'MP-LARK';
    //#endif
    // #ifdef MP-QQ
    platForm = 'MP-QQ';
    //#endif
    // #ifdef MP-KUAISHOU
    platForm = 'MP-KUAISHOU';
    //#endif
    // #ifdef QUICKAPP-WEBVIEW
    platForm = 'QUICKAPP-WEBVIEW';
    //#endif
    return platForm;

}

/**
 * 获取小程序胶囊居中对齐的高度
 */
export const getNavHeight = function () {
    let platForm = getPlatform();
    if (platForm === 'MP-WEIXIN') {
        let res = uni.getSystemInfoSync();
        let menuButtonInfo = uni.getMenuButtonBoundingClientRect();
        let navHeight =
            menuButtonInfo.height + (menuButtonInfo.top - res.statusBarHeight) * 2;
        return navHeight
    } else {
        return 44;
    }
}

/**
 * 手机顶部高度
 */
export const getPhoneTopHeight = function () {
    let platForm = getPlatform();
    if (platForm === 'MP-WEIXIN') {
        let height = uni.getSystemInfoSync().statusBarHeight;
        return height
    } else {
        return 0;
    }

}

/**
 * 获取当前元素位置
 */
export const getRect = function (selector) {
    return new Promise((resolve) => {
        let view = uni.createSelectorQuery().select(selector);
        view.fields({
            size: true,
            rect: true,
            scrollOffset: true
        }, (res) => {
            resolve(res);
        }).exec();
    })
}

/**
 * 判定两位小数是否为0
 */
export const intercept = function (data) {
    let ary = [];
    data.forEach((item, index) => {
        ary = String(item.full_money).split(".");
        // console.log(ary);
        if (ary[1] == "00") {
            item.full_money = ary[0];
        } else {
            item.full_money = ary.join('.');
        }
    });
}

String.prototype.formatTime = function formatTime(template) {
    typeof template === 'undefined' ? template = "{0}年{1}月{2}日 {3}:{4}:{5}" : null;
    let matchAry = this.match(/\d+/g)
    template = template.replace(/\{(\d+)\}/g, (x, y) => {
        let val = matchAry[y] || '00'
        val.length < 2 ? val = '0' + val : null
        return val
    })
    return template
}
// let time = '2022-5-30 12:0:0';
// console.log(time.formatTime("{0}_{1}_{2} {3}:{4}:{5}")); // 2022_05_30 12:00:00